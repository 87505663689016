import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,[_c(VRow,{staticClass:"align-center"},[_c(VCol,{attrs:{"cols":"6","lg":"4","offset":"6","offset-lg":"8"}},[_c(VTextField,{attrs:{"solo":"","flat":"","hide-details":"","label":_vm.$t('searchKeywordPlaceholder'),"append-icon":"mdi-magnify"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1)],1)],1),_c(VCardText,[_c(VDataTable,{staticClass:"hasAction",attrs:{"headers":_vm.headers,"items":_vm.emails,"search":_vm.keyword,"items-per-page":_vm.pageSize,"loading":_vm.dataLoading,"options":_vm.options,"loading-text":_vm.$t('dataLoading'),"fixed-header":true,"server-items-length":_vm.emailsCount,"footer-props":{
        disableItemsPerPage: true,
        itemsPerPageOptions: [_vm.pageSize]
      }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.rowClicked}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }