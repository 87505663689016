<template>
  <v-card flat>
    <v-card-title>
      <v-row class="align-center">
        <v-col cols="6" lg="4" offset="6" offset-lg="8">
          <v-text-field
            v-model="keyword"
            solo
            flat
            hide-details
            :label="$t('searchKeywordPlaceholder')"
            append-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="emails"
        :search="keyword"
        :items-per-page="pageSize"
        :loading="dataLoading"
        :options.sync="options"
        :loading-text="$t('dataLoading')"
        :fixed-header="true"
        :server-items-length="emailsCount"
        :footer-props="{
          disableItemsPerPage: true,
          itemsPerPageOptions: [pageSize]
        }"
        class="hasAction"
        @click:row="rowClicked"
      ></v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    keyword: '',
    dataLoading: false,
    options: {},
    pageSize: 12
  }),

  computed: {
    emails() {
      return [
        {
          id: 1,
          sender: 'Christian Hake',
          recipient: 'Timo Denger',
          cc: 'Sven Endmann',
          bcc: 'Marc Grein',
          subject: 'Guten Morgen',
          body: 'Sehr geehrte Herren,\n\nich wünsche Ihnen einen guten Morgen und freue mich auf unser heutiges gemeinsames Meeting.\n\nViele Grüße,\nChristian Hake',
          status: 'Gesendet',
          sendingDateTime: '13.04.2023 08:00:00'
        },
        {
          id: 2,
          sender: 'Christian Hake',
          recipient: 'Sven Endmann',
          cc: 'Timo Denger',
          bcc: 'Marc Grein',
          subject: 'Guten Abend',
          body: 'Sehr geehrte Herren,\n\nich wünsche Ihnen einen guten Abend und bedanke mich für unser heutiges gemeinsames Meeting.\n\nViele Grüße,\nChristian Hake',
          status: 'Ausstehend',
          sendingDateTime: '13.04.2024 18:00:00'
        }
      ];
    },

    emailsCount() {
      return this.emails.length;
    },

    headers() {
      return [
        {
          text: this.$t('emailDetails.sender'),
          sortable: true,
          filterable: true,
          value: 'sender'
        },
        {
          text: this.$t('emailDetails.recipient'),
          sortable: true,
          filterable: true,
          value: 'recipient'
        },
        {
          text: this.$t('emailDetails.subject'),
          sortable: true,
          filterable: true,
          value: 'subject'
        },
        {
          text: this.$t('emailDetails.status'),
          sortable: true,
          value: 'status',
          filterable: false
        },
        {
          text: this.$t('emailDetails.sendingDateTime'),
          sortable: true,
          filterable: false,
          value: 'sendingDateTime'
        }
      ];
    }
  },

  methods: {
    rowClicked(item) {
      this.$router.push({
        name: 'EmailDetails',
        params: { id: item.id }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.v-data-table tbody tr td:hover {
  cursor: pointer;
}
</style>
